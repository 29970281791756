import React, { useState } from "react";
import { Container, Image, Row, Col, Modal, Button } from "react-bootstrap";
import "./AboutUs.css";
import CardComponentTeacher from "../components/CardComponentTeacher";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const AboutUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const sausarBranch = [
    {
      id: 1,
      title: "Kishan Kr. Singh",
      image: "./images/aboutUs/t5.jpg",
      text: "Mr.Kishan Singh is the Administrative Director of Midas Taj coaching classes. He has completed his MBA in Marketing/ H.R from GH Raisoni (Nagpur University). He is having 8+ years of management experience. Mr. Kishan is responsible for all the management related activities of the Institute.",
      subtitle: "Administrative Director",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 2,
      title: "Satish Kr. Vishwakarma",
      image: "./images/aboutUs/t4.jpg",
      text: "Mr Satish is Regional Branch Head in Midas Taj coaching classes Pvt.Ltd. He has completed his M.B.A in Marketing/Operations from GH Raisoni (Nagpur University). He is having 11+ years of marketing and operations experience. Mr. Satish is responsible for all branch operation and marketing related activities",
      subtitle: "Regional Branch Head",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 3,
      title: "Naresh Kr. Rawani",
      image: "./images/aboutUs/t7.jpg",
      text: "Mr Naresh Rawani is the Finance Officer of Midas Taj coaching classes. He has completed his M.com in Accounts from IGNU. He is having 4+ years of management experience. Mr Naresh is responsible for all the Finance related activities of the Institute.",
      subtitle: "Finance related activities",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 4,
      title: "Rohit Kr. Singh",
      image: "./images/aboutUs/t6.jpg",
      text: "Mr Rohit is a Academic Head and Biology Lecturer in Midas Taj coaching classes. He has completed his M.Sc in Zoology from Ranchi University. He is having 12+ years of teaching experience in teaching Biology subject to NEET and AIIMS and medical students.",
      subtitle: "Academic Head & Biology Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 5,
      title: "Randhir Kumar",
      image: "./images/aboutUs/t13.jpg",
      text: "Mr Randhir Kumar is a Chemistry Lecturer in Midas Taj coaching classes. He has completed his B.Tech in Electrical Engineering from Rajasthan Technical University, Kota. He is having 7+ years of teaching experience in IIT JEE Mains and Advance, NEET.",
      subtitle: "Chemistry Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 6,
      title: "Banavathu Kishore",
      image: "./images/aboutUs/t14.jpg",
      text: "Mr Banavathu Kishore is a Chemistry Lecturer in Midas Taj coaching classes. He has completed his M.Sc. in Chemistry from IIT Bombay. He is having 12+ years of teaching experience in IIT JEE Mains and Advance, NEET.",
      subtitle: "Academic Head & Chemistry Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 7,
      title: "Sachin Singh Chauhan",
      image: "./images/aboutUs/t11.jpg",
      text: "Mr Sachin Singh Chauhan is a Mathematics Lecturer in Midas Taj coaching classes. He has completed his M.Sc in Mathemtics from IIT, Guwahati (Assam). He is having 10+ years of teaching experience in subject to IIT, JEE and ADVANCE.",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 8,
      title: "Saket Singh",
      image: "./images/aboutUs/t12.jpg",
      text: "Mr Saket is a Mathematics  Lecturer in Midas Taj coaching classes. He has completed his B.Sc in Mathematics from Vinoba Bhave University, Jharkhand and B.Ed from CRSU Jind. He is having 8+ years of teaching experience in Mathematics subject.      ",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 9,
      title: "Mihir Choudhary",
      image: "./images/aboutUs/t15.jpg",
      text: "Mr. Mihir Choudhary is a Mathematics Lecturer at Midas Taj Coaching Classes. He has completed his B.Tech. from MANIT Bhopal, M.P. He has over 2 years of teaching experience in subjects related to IIT, JEE, and ADVANCE.",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 10,
      title: "Suraj Trivedi",
      image: "./images/aboutUs/t27.jpg",
      text: "Mr. Suraj Trivedi is a Mathematics Lecturer at Midas Taj Coaching Classes. He has completed his M.Sc. and M.Phil from IIT Bombay. He has over 5 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 11,
      title: "Koushik Roy",
      image: "./images/aboutUs/t8.jpg",
      text: "Mr. Koushik Roy is a Chemistry Lecturer at Midas Taj Coaching Classes. He has completed his M.Sc. in Chemistry from IIT Madras. He has over 3 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Chemistry Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 12,
      title: "Kanhaiya",
      image: "./images/aboutUs/t39.jpg",
      text: "Mr. Kanhaiya is a Chemistry Lecturer at Midas Taj Coaching Classes. He has completed his M.sc. from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Chemistry Lecturer",
      backgroundColor: "var(--red-color)",
    },

    {
      id: 13,
      title: "Nikhil Kumawat",
      image: "./images/aboutUs/t30.jpg",
      text: "Mr. Nikhil Kumawat is a Mathematics Lecturer at Midas Taj Coaching Classes. He has completed his M.sc. from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 14,
      title: "Vishal Shah",
      image: "./images/aboutUs/t34.jpg",
      text: "Mr. Vishal Shah is a Mathematics Lecturer at Midas Taj Coaching Classes. He has completed his B.Tech. from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 15,
      title: "Ashutosh Singh",
      image: "./images/aboutUs/t35.jpg",
      text: "Mr. Ashutosh Singh is a Physics Lecturer at Midas Taj Coaching Classes. He has completed his B.Tech from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Physics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 16,
      title: "Deepak Gupta",
      image: "./images/aboutUs/t38.jpg",
      text: "Mr. Deepak Gupta is a Chemistry Lecturer at Midas Taj Coaching Classes. He has completed his M.sc. from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Chemistry Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 17,
      title: "Suresh Kumar",
      image: "./images/aboutUs/t24.jpg",
      text: "Mr. Suresh Kumar is a Physics Lecturer at Midas Taj Coaching Classes. He has completed his B.Tech from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Physics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 18,
      title: "Vinay Kr. Malviy",
      image: "./images/aboutUs/t16.jpg",
      text: "Mr. Vinay Kr. Malviy is a Biology Lecturer in Midas Taj coaching classes. He has Completed his M.SC in Botany from Govt. BHEL College, Bhopal (MP). He is Having 6+ Years of teaching experience in Biology Subject.",
      subtitle: "Biology Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 19,
      title: "Sachin Choure",
      image: "./images/aboutUs/t20.jpg",
      text: "Mr Sachin Choure is a Physics Lecturer in Midas Taj coaching classes. He has completed his B.Tech. in from UEC,M.P. He is having 2+ years of teaching experience in subject to IIT, JEE and ADVANCE.",
      subtitle: "Physics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 20,
      title: "Dr.Nidhi Upgade",
      image: "./images/aboutUs/t10.jpg",
      text: "Dr. Nidhi Upgade is a Social Science Lecturer in Midas Taj coaching classes. She has completed her BDS from SAIMS,Indore,M.P. she is having 2+ years of teaching experience in Social Science subject.",
      subtitle: "Social Science Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 21,
      title: "Abhishek Verma",
      image: "./images/aboutUs/t9.jpg",
      text: "Mr Abhishek Verma is a Social Science Lecturer in Midas Taj coaching classes. He has completed his M.A in Poltical Science from CIMTI, Ridhora,M.P, M.Ed, B.A( Hindi ), D.EL.ED.  He is having 8+ years of teaching experience in Social Science subject.",
      subtitle: "Social Science Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 22,
      title: "Trapti Rajpoot",
      image: "./images/aboutUs/t17.jpg",
      text: "Ms Trapti Rajpoot is a Biology Lecturer in Midas Taj coaching classes. She has completed her M.Sc. in microbiology and B.Ed. from chhindwara university. She is having 5+ years of teaching experience in Biology subject",
      subtitle: "Biology Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 23,
      title: "Vaibhav Arun Dongre",
      image: "./images/aboutUs/t21.jpg",
      text: "Mr Vaibhav Arun Dongre is a Mental Ability, English Grammar & Social Science in Midas Taj coaching classes. He has completed his Engineering in Electrical and Electronic from LNCT Bhopal, Bhopal. He is having 4+ years of teaching experience in NTSE",
      subtitle: "Social Science Lecturer",
      backgroundColor: "var(--green-color)",
    },

    // Add more dummy data as needed
  ];

  const seoniBranch = [
    {
      id: 1,
      title: "Manoj Kumar",
      image: "./images/aboutUs/t22.jpg",
      text: "Mr Manoj Kumar is a Academic Head and Mathematics Lecturer in Midas Taj coaching classes. He has completed his M.Sc in Physics from VBU Hazaribhag. He is having 8+ years of teaching experience in Mathematics subject.",
      subtitle: "Academic Head & Mathematics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 2,
      title: "Shobhit Sisodiya",
      image: "./images/aboutUs/seoni/s4.jpg",
      text: "Mr Shobhit Sisodiya is a Regional Officer in Midas Taj coaching classes. He has completed his MA in Political Science. He is having 3+ years of marketing and operations experience. Mr. Shobhit is responsible for marketing related activities.",
      subtitle: "Regional Officer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 3,
      title: "Anand Kumar Bind",
      image: "./images/aboutUs/seoni/s6.jpg",
      text: "Mr. Anand Kumar Bind is a Mathematics Lecturer at Midas Taj Coaching Classes. He has completed his M.sc. from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 4,
      title: "Prashant Galav",
      image: "./images/aboutUs/seoni/s7.jpg",
      text: "Mr. Prashant Galav is a Chemistry Lecturer at Midas Taj Coaching Classes. He has completed his M.Tech from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Chemistry Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 5,
      title: "Ujjwal Prasanna Saket",
      image: "./images/aboutUs/seoni/s5.jpg",
      text: "Mr Ujjwal Prasanna Saket is a Physics Lecturer in Midas Taj coaching classes. He has completed his M.Sc in Applied Physics. He is having 5+ years of teaching experience in Physics subject.",
      subtitle: "Physics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 6,
      title: "Manoj Kumar Kanchan",
      image: "./images/aboutUs/seoni/s2.jpg",
      text: "Mr. Manoj Kumar Kanchan is a Chemistry Lecturer at Midas Taj Coaching Classes. He has completed his B.Tech from Uttar Pradesh Textile Technology Institute College in Kanpur and has over 5+ years of teaching experience in Chemistry Subject.",
      subtitle: "Chemistry Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 7,
      title: "Sumit Mulmule",
      image: "./images/aboutUs/seoni/s1.jpg",
      text: "Mr. Sumit Mulmule is a Social Science Lecturer at Midas Taj Coaching Classes. He has completed his B.A in Political Science and has over 3 years of teaching experience in Social Science Subject. ",
      subtitle: "Social Science Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 8,
      title: "Purvashi Pokharial",
      image: "./images/aboutUs/seoni/s3.jpg",
      text: "Ms Purvashi Pokharial is a Biology Lecturer in Midas Taj coaching classes. She has completed her M.Sc. in Zoology. She is having 4+ years of teaching experience in Biology subject",
      subtitle: "Biology Lecturer",
      backgroundColor: "var(--red-color)",
    },
  ];
  const pandhurnaBranch = [
    {
      id: 1,
      title: "Sanghya Singh",
      image: "./images/aboutUs/pandhurna/p1.jpg",
      text: "Ms. Sanghya is an Admin Officer at Midas Taj Coaching Classes. She has completed her B.Tech from UEC. She has over 1 year of marketing and operations experience. Ms. Sanghya is responsible for marketing-related activities.",
      subtitle: "Admin officer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 2,
      title: "Lokesh Kumawat",
      image: "./images/aboutUs/t31.jpg",
      text: "Mr. Lokesh Kumawat is a Mathematics Lecturer at Midas Taj Coaching Classes. He has completed his M.sc. from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Mathematics Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 3,
      title: "ShivShankar Yadav",
      image: "./images/aboutUs/t29.jpg",
      text: "Mr. ShivShankar Yadav is a Physics Lecturer at Midas Taj Coaching Classes. He has completed his B.Tech. from IIT Delhi and has over 1 years of teaching experience in IIT JEE Mains and Advanced, as well as NEET.",
      subtitle: "Physics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 4,
      title: "Vishal Pratap Singh",
      image: "./images/aboutUs/t26.jpg",
      text: "Mr. Vishal Pratap Singh is a Social Science Lecturer at Midas Taj Coaching Classes. He has completed his MBA and B.Ed. from Delhi University. He has over 8 years of teaching experience in Foundation courses",
      subtitle: "Social Science Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 5,
      title: "Yash Lonare",
      image: "./images/aboutUs/t23.jpg",
      text: "Mr Yash Lonare is a Physics Lecturer in Midas Taj coaching classes. He has completed his B.Tech. in from UEC, Ujjain,M.P. He is having 2+ years of teaching experience in subject to IIT, JEE and ADVANCE.",
      subtitle: "Physics Lecturer",
      backgroundColor: "var(--green-color)",
    },
    {
      id: 6,
      title: "Sakshi Singh",
      image: "./images/aboutUs/t25.jpg",
      text: "Ms. Sakshi Singh is a Chemistry Lecturer at Midas Taj Coaching Classes. She has completed her M.Sc. in Chemistry from RTMNU, Nagpur. She has over 3 years of teaching experience in Foundation courses. ",
      subtitle: "Chemistry Lecturer",
      backgroundColor: "var(--red-color)",
    },
    {
      id: 7,
      title: "Muskan Wadhwa",
      image: "./images/aboutUs/t33.jpg",
      text: "Ms. Muskan Wadhwa is a Biology Lecturer at Midas Taj Coaching Classes. She has completed her M.Sc. in Botany from Govt. Holkar Science College, Indore. She has over 2 years of teaching experience in Foundation courses. ",
      subtitle: "Biology Lecturer",
      backgroundColor: "var(--green-color)",
    },
  ];

  return (
    <div className="container" id="aboutUs">
      <Image src="./images/aboutUs/t1.jpg" style={{ width: "100%" }} rounded />

      <h1
        className="headingTitleNormal display-6"
        style={{ textAlign: "left" }}
      >
        <span className="headingTitleGradient">{"About "}</span>
        {"Midas"}
      </h1>

      {/* <h5 className="display-6">About Midas</h5> */}
      <p>
        MIDAS TAJ Classes Pvt. Ltd. Established with the aim of imparting
        quality education in the field IIT-JEE MAINS & ADVANCE, NEET, PRE-
        FOUNDATION, NTSE, KVPY, Science Olympiads and Math Olympiad. In view of
        the challenging environment, a responsibility is placed is placed on the
        young men & women possessing skills to manage the complex business
        environment. For this, the students of new era are required to be well
        equipped with knowledge, skill and inspiration to lead. We at MIDAS TAJ
        Classes Pvt. Ltd. Aim toward providing quality education, which will
        prove to be meaningful to the student and will give an complex business,
        and service oriented environment. We invite all those who share our
        vision, goal and purpose and will give justice to our efforts in
        building a new cadre of IITIANS & DOCTORS.
      </p>

      <div>
        <h1
          className="headingTitleNormal display-6"
          style={{ textAlign: "left" }}
        >
          <span className="headingTitleGradient">{"Message from "}</span>
          {"Director"}
        </h1>

        {/* <h5 className="display-6">Message From Director </h5> */}
        <Row>
          <Col sm={7}>
            <p>
              Only a father can understand the level of anxiety of a father. I
              am sure, like your children, you yourself are also on a cross road
              and it has become quite a difficult to make a selection of the
              right part that cab Lead to a right destination, that, is full of
              worldly achievements and satisfaction of fulfillment. All our life
              efforts. Our sacrifice and our hard work have to a promise a
              brighter career to our child. We have dreams for our children and
              we purse to bring them into reality. However, the present day
              situation is making our journey a difficult task. Dear student, As
              you stand upon the threshold that would mark the beginning of your
              career,many important decisions await your consideration. Along
              with hard work and intelligence, another crucial factor that would
              play a decisive role in your success is availing of the right
              guidance and being in the company of like-minded people. This is
              where the choice of the best coaching institute to realize your
              dream of a good rank in IIT-JEE MAINS & ADVANCE, NEET, PRE-
              FOUNDATION, NTSE, KVPY, Science Olympiads and Math Olympiads comes
              into play. IIT-JEE is not, as believed by many, a difficult
              examination albeit a different and hence requires a motivated and
              efficient approach with our experience and results, we feel
              confident that PACE offers the perfect balance of elite faculty,
              student supporting a healthy, compretitive environment to make
              your time here a memorable one. I believe institute are known by
              our best faculties.
              <br /> Founder & Director <br />
              Er. Vikash Kr. Singh
            </p>
          </Col>
          <Col sm={5}>
            <Image src="./images/aboutUs/vsir.jpeg" style={{ width: "100%" }} />
          </Col>
        </Row>
        <div id="faculties">
          <h1
            className="headingTitleNormal display-6"
            style={{ textAlign: "left" }}
          >
            <span className="headingTitleGradient">{"Team "}</span>
            {"MIDAS"}
          </h1>
          {/* <h5 className="display-6">Our Faculties</h5> */}
          <div
            style={{
              backgroundColor: "var(--light-yellow-color)",
              padding: "40px 20px",
            }}
          >
            {/* ----------------------------------------------------------- */}

            <Tabs
              defaultActiveKey="Sausar"
              transition={false}
              id="noanim-tab-example"
              className="mb-3 scrollmenu"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tab eventKey="Sausar" title="Sausar">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1 class="headingTitleNormal display-3">
                    <span class="headingTitleGradient"> Branch : </span> Sausar
                  </h1>
                  <CardComponentTeacher dummyData={sausarBranch} />
                </div>
              </Tab>
              <Tab eventKey="Seoni" title="Seoni">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1 class="headingTitleNormal display-3">
                    <span class="headingTitleGradient"> Branch : </span> Seoni
                  </h1>

                  <CardComponentTeacher dummyData={seoniBranch} />
                </div>
              </Tab>
              <Tab eventKey="Pandhurna" title="Pandhurna">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1 class="headingTitleNormal display-3">
                    <span class="headingTitleGradient"> Branch : </span>{" "}
                    Pandhurna
                  </h1>

                  <CardComponentTeacher dummyData={pandhurnaBranch} />
                </div>
              </Tab>
            </Tabs>

            {/* ---------------------------------------------------------------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
